import { http } from './config'

export default {
    login: (email, password) => {
        return http.post('autenticar/login',
            {
                "email": email,
                "password": password
            }
        )
    },
    logout: () => {
        return http.post('autenticar/logout')
    },
    keyTimeout: () => {
        return http.get('autenticar/keytimeout')
    },
    validarManutencao(){
        return http.get("autenticar/manutencao")
    },
    buscarTelefoneSuporte(){
        return http.get("autenticar/atendimento")
    }
}
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router/index'
import store from './store/store'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import money from 'v-money'
import "@/helpers/filters";
import CxltToastr from 'cxlt-vue2-toastr'
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
import './assets/css/style.css'
import HelperPlugin from './helpers/helper'

Vue.use(BootstrapVue)
Vue.use(VueLoading, {
  canCancel: false,
  isFullPage: true,
  zIndex: 9999,
})
Vue.use(CxltToastr, {
  position: 'top center',
  showDuration: 500,
  hideDuration: 1000,
  timeOut: 5000
})
Vue.use(HelperPlugin)

Vue.component('loading', VueLoading)

Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: '',
  suffix: '',
  precision: 2,
  masked: false
})

// Vue.config.errorHandler = (err, vm, info) => {
//   // err: error trace
//   // vm: component in which error occured
//   // info: Vue specific error information such as lifecycle hooks, events etc.
//   console.log('Error trace:');
//   console.log(err);
//   console.log('View Component:');
//   console.log(vm);
//   console.log('Info:');
//   console.log(info);
// };

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})

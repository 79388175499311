import Vue from "vue";

import Moment from 'moment';

Vue.filter('formatDateTime', function (value) {
    if (!value) {
        return "";
    }

    if (value) {
        return Moment(String(value)).format('DD/MM/YYYY hh:mm:ss');
    }
});

Vue.filter('formatDate', function (value) {
    if (!value) {
        return "";
    }

    if (value) {
        return Moment(String(value)).format('DD/MM/YYYY');
    }
});

Vue.filter('money', function (value, decimals = 2) {
    if (!value && value !== 0) {
        return "";
    }
    let val = (value / 1).toFixed(decimals).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
});

Vue.filter('moneyIfNumberElseString', function (value, decimals = 2) {
    if (isNaN(Number(value)))
        return value;

    if (!value && value !== 0) {
        return "";
    }
    let val = (value / 1).toFixed(decimals).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
});

Vue.filter("toCapitalized", function (value) {
    if (!value) {
        return "";
    }
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("toUpper", function (value) {
    if (!value) {
        return "";
    }
    value = value.toString();
    return value.toUpperCase();
});

Vue.filter("toLower", function (value) {
    if (!value) {
        return "";
    }

    value = value.toString();
    return value.toLowerCase();
});

Vue.filter('cnpj', function (value) {
    if (!value) {
        return "";
    }

    value = value.toString();
    value = value.padStart(14, '0');
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
});

Vue.filter('cpf', function (value) {
    if (!value) {
        return "";
    }

    value = value.toString();
    value = value.padStart(11, '0');
    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
});

Vue.filter('cep', function (value) {
    if (!value) {
        return "";
    }

    value = value.toString();
    value = value.padStart(8, '0');
    return value.replace(/^(\d{5})(\d{3})/, '$1-$2');
});

Vue.filter('phone', function (value) {
    if (!value) {
        return "";
    }

    value = (value.toString() * 1).toString();

    if (value.length == 8) {
        return value.replace(/^(\d{4})(\d{4})/, '$1-$2');
    }

    if (value.length == 9) {
        return value.replace(/^(\d{5})(\d{4})/, '$1-$2');
    }

    if (value.length == 10) {
        return value.replace(/^(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
    }

    if (value.length == 11) {
        return value.replace(/^(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
    }

    if (value.length == 12) {
        return value.replace(/^(\d{3})(\d{5})(\d{4})/, '($1)$2-$3');
    }
    return value;
});

import Axios from 'axios'
import Store from '../store/store'
import Router from '../router/index'
import Qs from 'qs'
import { baseUrls } from '../settings.js'

var httpNoLoadingPendingRequestList = [];

const httpNoLoadingFunctions = {
    cancelPendingRequests: function () {
        httpNoLoadingPendingRequestList.forEach((item) => {
            item.cancel("Request canceled in axios-config(no loading)...");
        });
        httpNoLoadingPendingRequestList = [];
    }
};

const httpNoLoading = Axios.create({
    baseURL: baseUrls.api,
    headers: {
        'Content-Type': 'application/json'
    },
    paramsSerializer: function (params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' })
    },
})

httpNoLoading.interceptors.request.use(
    config => {
        config.headers['X-Api-Key'] = Store.state.login.xApiKey;
        let source = Axios.CancelToken.source();
        config.cancelToken = source.token;
        httpNoLoadingPendingRequestList.push(source);
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

httpNoLoading.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if ((error.response && error.response.status) && (error.response.status === 401 || error.response.status === 403)) {
        if (!Router.currentRoute.path.includes('login')) {
            Store.commit('ui/MSG', error.response.data.msg);
            Store.commit('ui/MSG_TYPE', error.response.data.type);
            Router.push("/login");
        }
    }
    return Promise.reject(error);
});

export { httpNoLoading };
export { httpNoLoadingFunctions };
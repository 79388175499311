import Vue from 'vue'
import Router from 'vue-router'
import Store from '../store/store'
import Autenticar from "../services/autenticar";
import Moment from 'moment';
import { httpNoLoadingFunctions } from '../services/configNoLoading';
import { httpFunctions } from '../services/config';
import { EventBus } from "../helpers/eventBus";
import GrupoPermissaoService from "../services/grupoPermissao";

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const PageNotFound = () => import('@/views/Page404')
const Inicio = () => import('@/views/Inicio')
const Login = () => import('@/views/Login')
const Cadastro = () => import('@/views/Cadastro')
const Logout = () => import('@/views/Logout')
const Atendimento = () => import('@/views/Atendimento')
const AlterarSenha = () => import('@/views/configuracao/senha/AlterarSenha')
const Geral = () => import('@/views/configuracao/geral/Geral')
const Revenda = () => import('@/views/configuracao/dados_revenda/DadosRevenda')
const GrupoPermissao = () => import('@/views/configuracao/grupo_permissao/GrupoPermissao')
const GrupoPermissaoForm = () => import('@/views/configuracao/grupo_permissao/GrupoPermissaoForm')
const Usuario = () => import('@/views/configuracao/usuario/Usuario')
const UsuarioForm = () => import('@/views/configuracao/usuario/UsuarioForm')
const Cron = () => import('@/views/configuracao/cron/ListaCron')
const Clientes = () => import('@/views/vendas/clientes/Clientes')
const Cliente = () => import('@/views/vendas/clientes/Cliente')
const Orcamentos = () => import('@/views/vendas/orcamentos/Orcamentos')
const Orcamento = () => import('@/views/vendas/orcamentos/Orcamento')
const OrcamentoNovo = () => import('@/views/vendas/orcamentos/OrcamentoNovo')
const OrcamentoEditar = () => import('@/views/vendas/orcamentos/OrcamentoEditar')
const Pedidos = () => import('@/views/vendas/pedidos/Pedidos')
const Pedido = () => import('@/views/vendas/pedidos/Pedido')
const Produtos = () => import('@/views/vendas/produtos/Produtos')
const Produto = () => import('@/views/vendas/produtos/Produto')
const Premiacoes = () => import('@/views/financeiro/premiacoes/Premiacoes')
const Financeiro = () => import('@/views/financeiro/segunda_via/SegundaVia')
const RecuperarSenha = () => import('@/views/senha/recuperarSenha')

Vue.use(Router)

const pageTitleEnd = ' - Sistema de Vendas Diretas - Dicomp';

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/inicio',
      name: 'Início',
      component: DefaultContainer,
      meta: { requiresAuth: true, title: 'Bem vindo' + pageTitleEnd },
      children: [
        {
          path: 'inicio',
          name: '',
          component: Inicio,
          meta: { requiresAuth: true, title: 'Bem vindo' + pageTitleEnd },
        },
        {
          path: 'configuracao',
          name: 'Configurações',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            /* {
              path: 'senha/alterar',
              name: 'Alterar Senha',
              component: AlterarSenha
            }, */
            {
              path: 'geral',
              name: 'Configurações Gerais',
              component: Geral
            },
            {
              path: 'grupo_permissao',
              name: 'Grupo de usuários',
              redirect: '/configuracao/grupo_permissao',
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: '',
                  component: GrupoPermissao,
                  meta: { title: 'Grupos de usuários' + pageTitleEnd },
                },
                {
                  path: 'grupo/novo',
                  name: 'Grupo de usuários / Novo',
                  component: GrupoPermissaoForm,
                },
                {
                  path: 'grupo/:id/editar',
                  name: 'Grupo de usuários / Editar',
                  component: GrupoPermissaoForm,
                  props: true
                },
              ],
            },
            {
              path: 'usuario',
              name: 'Usuários',
              redirect: '/configuracao/usuario',
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: '',
                  component: Usuario,
                  meta: { title: 'Usuários' + pageTitleEnd },
                },
                {
                  path: 'usuario/novo',
                  name: 'Usuários / Novo',
                  component: UsuarioForm,
                },
                {
                  path: 'usuario/:id/editar',
                  name: 'Usuários / Editar',
                  component: UsuarioForm,
                  props: true
                },
              ],
            },
            {
              path: 'dados_revenda',
              name: 'Dados da revenda',
              component: Revenda
            },
            {
              path: 'cron',
              name: 'Lista de crons',
              component: Cron
            }
          ]
        },
        {
          path: 'vendas',
          name: 'Vendas',
          redirect: '/vendas/orcamentos',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'clientes',
              name: 'Clientes',
              redirect: '/vendas/clientes',
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: '',
                  component: Clientes,
                  meta: { title: 'Clientes' + pageTitleEnd },
                },
                {
                  path: 'cliente/:codigo',
                  name: 'Cliente',
                  component: Cliente,
                  props: true
                }
              ],
            },
            {
              path: 'produtos',
              name: 'Produtos',
              redirect: '/vendas/produtos',
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: '',
                  component: Produtos,
                  meta: { title: 'Produtos' + pageTitleEnd },
                },
                {
                  path: 'produto/:codigo',
                  name: 'Produto',
                  component: Produto,
                  props: true
                }
              ],
            },
            {
              path: 'orcamentos',
              name: 'Orçamentos',
              redirect: '/vendas/orcamentos',
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: '',
                  component: Orcamentos,
                  meta: { title: 'Orçamentos' + pageTitleEnd },
                },
                {
                  path: 'orcamento/novo/:cliente_codigo',
                  name: 'Orçamento / Novo para cliente',
                  component: OrcamentoNovo,
                  props: true
                },
                {
                  path: 'orcamento/novo',
                  name: 'Orçamento / Novo',
                  component: OrcamentoNovo,
                },
                {
                  path: 'orcamento/:id/editar',
                  name: 'Orçamento / Editar',
                  component: OrcamentoEditar,
                  props: true
                },
                {
                  path: 'orcamento/:id',
                  name: 'Orçamento / Visualizar',
                  component: Orcamento,
                  props: true
                },
              ],
            },
            {
              path: 'pedidos',
              name: 'Pedidos',
              redirect: '/vendas/pedidos',
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: '',
                  component: Pedidos,
                  meta: { title: 'Pedidos' + pageTitleEnd },
                },
                {
                  path: 'pedido/:codigo',
                  name: 'Pedido',
                  component: Pedido,
                  props: true
                },
              ]
            },
          ]
        },
        {
          path: 'financeiro',
          name: 'Financeiro',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'segunda_via',
              name: '2ª Via de Boletos',
              component: Financeiro
            },
            {
              path: 'premiacoes',
              name: 'Premiações',
              component: Premiacoes
            }
          ]
        },
        {
          path: '/atendimento',
          name: 'Atendimento',
          component: Atendimento
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/cadastro/:code',
      name: 'Cadastro',
      component: Cadastro,
    }
    ,
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '/senha',
      name: 'Recuperar Senha',
      component: RecuperarSenha
    },
    {
      path: "*",
      name: 'Página não encontrada.',
      component: PageNotFound
    }
  ]
});

function clearLoginData() {
  Store.commit("login/RESET");
  Store.commit("carrinho/RESET");
  localStorage.clear();
}

router.beforeEach((to, from, next) => {

  httpNoLoadingFunctions.cancelPendingRequests();
  httpFunctions.cancelPendingRequests();

  if (from.name == "Orçamento / Editar" && to.path == "/vendas/produtos") {
    Store.commit("carrinho/RESET");
  }

  if(from.fullPath == '/' && Store.state.ui.salvandoPedido){
    Store.commit('ui/RESET');
  }

  EventBus.$emit("onBreadcrumbButtonSet", { text: null, url: null });

  if (to.meta && to.meta.title)
    document.title = to.meta.title;
  else
    document.title = to.name + pageTitleEnd;

  if (to.matched.some(record => record.path == "/logout")) {
    Autenticar.logout().finally(() => {
      clearLoginData();
      router.replace('/login');
    });
    next();
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {

    validarPermissao(to.path);
    
    let expirationDate = Store.state.login.expirationDate;
    if (expirationDate) {
      let expiresAt = Moment(expirationDate);
      let now = Moment();
      if (expiresAt.isAfter(now)) {
        next();
      }
      else {
        router.replace('/login');
      }
    }
    else {
      router.replace('/login');
    }
  }
  else {
    next();
  }
});

function validarPermissao(path) {
  let pathPermissao = path;
  if (pathPermissao[pathPermissao.length] == "/")
    pathPermissao = pathPermissao.slice(0, -1);

  GrupoPermissaoService.gerenciaPermissao(pathPermissao).then(
    (response) => {
      if (response.data.data) {
        GrupoPermissaoService.getPermissao(pathPermissao).then(
          (response) => {
            if (!response.data.data) {
              router.push("./");
            }
          }
        );
      }
    }
  );
}

export default router;

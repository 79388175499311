import { http } from './config'

export default {
    getGruposPermissao: (data = null, params = null) => {
        params = params ? "?" + params : "/"
        return http.get('grupopermissao/list' + params, data)
    },
    getById: (id) => {
        return http.get('grupopermissao/' + id)
    },
    getMenus: (grupo_id) => {
        return http.get('grupopermissao/' + grupo_id + '/menus')
    },
    getUsuarios: (grupo_id) => {
        return http.get('grupopermissao/' + grupo_id + '/usuarios')
    },
    saveGrupoPermissao: (data) => {
        return http.post('grupopermissao/save', data)
    },
    deleteGrupoPermissao: (id) => {
        return http.delete('grupopermissao/' + id)
    },
    getPermissao: (url) => {
        return http.get('grupopermissao/verificapermissao?url=' + url)
    },
    gerenciaPermissao: (url) => {
        return http.get('grupopermissao/gerenciapermissao?url=' + url)
    },
    getGruposPermissaoDropbox: (data = null, params = null) => {
        params = params ? "?" + params : "/"
        return http.get('grupopermissao/listar-dropbox' + params, data)
    },
    getTodasPermissoes: () => {
        return http.get('grupopermissao/get-todas-permissoes')
    },
}
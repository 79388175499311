import Vue from 'vue';
import Vuex from 'vuex';
import CreatePersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const login = {
    namespaced: true,
    state: {
        user: null,
        resale: null,
        xApiKey: null,
        expirationDate: null,
    },
    mutations: {
        CURRENT_USER: (state, value) =>
            value ? (state.user = value) : (state.user = null),

        CURRENT_RESALE: (state, value) =>
            value ? (state.resale = value) : (state.resale = null),

        X_API_KEY: (state, value) =>
            value ? (state.xApiKey = value) : (state.xApiKey = null),

        EXPIRATION_DATE: (state, value) =>
            value ? (state.expirationDate = value) : (state.expirationDate = null),

        RESET: (state) => {
            state.expirationDate = null;
            state.user = null;
            state.resale = null;
            state.xApiKey = null;
        }
    }
};

const ui = {
    namespaced: true,
    state: {
        loading: false,
        msg: null,
        msg_type: null,
        salvandoPedido: false
    },
    mutations: {
        LOADING: (state, value) =>
            value ? (state.loading = value) : (state.loading = false),

        MSG: (state, value) =>
            value ? (state.msg = value) : (state.msg = null),

        MSG_TYPE: (state, value) =>
            value ? (state.msg_type = value) : (state.msg_type = null),

        SALVANDO_PEDIDO: (state, value) => 
            value ? (state.salvandoPedido = value) : (state.salvandoPedido = null),

        RESET: (state) => {
            state.loading = false;
            state.msg = null;
            state.msg_type = null;
            state.salvandoPedido = false;
        }
    }
};

const carrinho = {
    namespaced: true,
    state: {
        cartMga: null,
        cliente_codigo: null,
        lastChange: null,
    },
    mutations: {
        CURRENT_CART: (state, value) => {
            state.lastChange = new Date();
            if (value) {
                state.cartMga = value;
                if(!state.cliente_codigo){
                    state.cliente_codigo = value.cliente_codigo;
                }
            }
            else {
                state.cartMga = null;
                state.cliente_codigo = null;
            }
        },
        CURRENT_CART_ITEMS: (state, value) => {
            state.lastChange = new Date();
            // value ? (state.cartMga.items = value) : (state.cartMga.items = null);
            if(state.cartMga != null)
            {
                state.cartMga.items = value;
            }
        },
        RESET: (state) => {
            state.lastChange = new Date();
            state.cartMga = null;
            state.cliente_codigo = null;
        },
        CURRENT_CLIENT:(state, value) => {
            state.cliente_codigo = value;
        }
    }
};

const carrinhoitj = {
    namespaced: true,
    state: {
        cartItj: null,
        cliente_codigo: null,
        lastChange: null,
    },
    mutations: {
        CURRENT_CART_ITJ: (state, value) => {
            state.lastChange = new Date();
            if (value) {
                state.cartItj = value;
                if(!state.cliente_codigo){
                    state.cliente_codigo = value.cliente_codigo;
                }
            }
            else {
                state.cartItj = null;
                state.cliente_codigo = null;
            }
        },
        CURRENT_CART_ITEMS_ITJ: (state, value) => {
            state.lastChange = new Date();
            // value ? (state.cartItj.items = value) : (state.cartItj.items = null);
            if(state.cartItj != null)
            {
                state.cartItj.items = value;
            }    
        },
        RESET_ITJ: (state) => {
            state.lastChange = new Date();
            state.cartItj.items = null;
            state.cartItj = null;
            state.cliente_codigo = null;
        },
        CURRENT_CLIENT_ITJ:(state, value) => {
            state.cliente_codigo = value;
        }
    }
};

const configPadrao = {
    namespaced: true,
    state: {
        linkWhatsapp: "https://api.whatsapp.com/send?phone=55",
        limiteMaximoMargem: 70,
        limiteMaximoDivergenciaMargemOrc: 0.04
    }
};

const store = new Vuex.Store({
    plugins: [
        CreatePersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ],
    modules: {
        login: login,
        carrinho: carrinho,
        ui: ui,
        carrinhoitj: carrinhoitj,
        configPadrao: configPadrao
    }
})

export default store;
import moment from "moment";
import axios from "axios";

const HelperPlugin = {
    install: function (Vue, options) {
        if (!options) {
            options = {}
        }
        function showMsg(msg, type, title) {
            //https://github.com/chengxulvtu/cxlt-vue2-toastr/blob/master/README.en.md
            switch (type.trim()) {
                case 'success':
                    return Vue.prototype.$toast.success({
                        title: title == '' ? 'Sucesso' : title,
                        message: msg,
                        timeOut: 3000,
                        successColor: '#28a745'
                    });
                case 'warning':
                case 'warn':
                    return Vue.prototype.$toast.warn({
                        title: title == '' ? 'Atenção' : title,
                        message: msg,
                        showMethod: 'swing',
                        timeOut: 5000,
                        progressBar: true,
                        warningColor: '#ff9707'
                    });
                case 'danger':
                case 'error':
                    return Vue.prototype.$toast.error({
                        title: title == '' ? 'Desculpe-nos houve um erro.' : title,
                        message: msg,
                        showMethod: 'swing',
                        timeOut: 5000,
                        progressBar: true,
                        errorColor: '#dc3545'
                    });
                default:
                    return Vue.prototype.$toast.info({
                        title: title == '' ? 'Informação' : title,
                        message: msg,
                        infoColor: '#17a2b8'
                    });
            }
        }
        Vue.prototype.$helper = {
            showMsg(msg, type = 'info', title = '') {
                return showMsg(msg, type, title)
            },
            showErrorResponse(error) {
                if (axios.isCancel(error)) {
                    if (error != null && error.message != null)
                        console.log(error.message);
                    return;
                }

                let title = 'Erro';
                let msg = 'Houve um erro, por favor verifique se você está conectado à internet e tente novamente mais tarde.';
                let type = 'error';
                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.msg) {
                            msg = error.response.data.msg;
                        }
                        if (error.response.data.type) {
                            type = error.response.data.type;
                            if (type == 'warning') {
                                title = 'Atenção';
                            }
                            else {
                                title = 'Erro';
                            }
                        }
                    }
                }
                console.log(error);
                return showMsg(msg, type, title);
            },
            isNumeric(x) {
                return ((typeof x === 'number' || typeof x === 'string') && !isNaN(Number(x)));
            },
            isDate(x) {
                if (x == null || x == undefined)
                    return false;
                x = x.replace(/\//g, "-");
                if (moment(x, "MM-DD-YYYY HH:mm:ss").isValid() && !isNaN(x.replace(/([-:/\s])/gi, "")) ){
                    return true;
                } 
                if (moment(x, "DD-MM-YYYY HH:mm:ss").isValid() && !isNaN(x.replace(/([-:/\s])/gi, "")) ) {
                    return true;
                }
                if (moment(x, "MM-DD-YYYY").isValid() && !isNaN(x.replace(/([-:/\s])/gi, "")) ){
                    return true;
                } 
                if (moment(x, "DD-MM-YYYY").isValid() && !isNaN(x.replace(/([-:/\s])/gi, "")) ) {
                    return true;
                }
            }
        };
    }
}
export default HelperPlugin;
